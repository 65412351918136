import React from 'react';
import { Breadcrumb, Skeleton } from 'antd';
import { Row, Col, List, Avatar , Card} from 'antd';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link, useNavigate } from "react-router-dom";
import TicketServices from "../../services/TicketServices";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function Home() {
    const usr = JSON.parse(localStorage.getItem('usr'));
    const [notif, setNotif] = React.useState([]);
    const [pic, setPic] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [status, setStatus] = React.useState(null);
    let navigate = useNavigate();

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return '';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        },
    };
    const handleClickRoute = (menu) => {
        navigate(menu);
    }

    React.useEffect(() => {
        function fetch() {
            TicketServices.GetNotif().then((res) => {
                setNotif(res.data);
            });
            TicketServices.GetPic().then((res) => {
                setPic(res.data);
            });
            TicketServices.GetBestStatus().then((res) => {
                setStatus(res.data);
                const labels = [];
                const datas = [];
                res.data.forEach(row => {
                    labels.push(row.category_name);
                    datas.push(row.total);
                })
                const data = {
                    labels,
                    datasets: [
                        {
                            label: 'Total Status Pekerjaan',
                            data: datas,
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ],
                };
                setStatus(data);
            });
            setLoading(false);
        }
        fetch();
    }, [TicketServices])
    return (
        <div className="container mx-auto flex flex-wrap flex-row">
            <div className='w-full px-3'>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row gutter={4}>
                <Col span={16}>
                    <div className="w-full p-3 bg-white shadow-xl sm:rounded-lg">
                        {!loading ? <><label className='text-[20px]'>Tracking {notif.length}</label>
                            {status?.datasets?.length > 0 && <Bar options={options} data={status}/>}
                            <p>Note: Arahkan ke bar chart untuk melihat detail</p></>
                            : <Skeleton active />}
                    </div>
                </Col>
                <Col span={6}>
                    <Card title="Notification" bordered={false} style={{ width: 300 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={notif}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={<Link to={`/home/detail-ticket/${item.id_ticket}`}>Hallo,</Link>}
                                        description={`${item.nama_ticket} telah di disposisikan kepada anda`}
                                    />
                                </List.Item>
                            )}></List>
                    </Card>

                </Col>
            </Row>
            <div className="relative md:w-12/12 w-full px-3 my-3 bg-white shadow-xl ring-1 ring-gray-900/5 sm:rounded-lg">
                <div className="max-w-md p-3">
                    <label className='text-[15px] mb-2'>5 PIC Pekerjaan Terbanyak </label>
                    <table className='w-full border-[1px] text-center pt-2'>
                        <tr>
                            <th>Nama</th>
                            <th>Jumlah</th>
                        </tr>
                        {pic.map((row, key) => (<tr key={key}>
                            <td >{row.pic}</td>
                            <td>{row.total}</td>
                        </tr>))}
                    </table>
                </div>
            </div>
        </div >)
}

export default Home