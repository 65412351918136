import * as React from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from "./components/privateRoute";
import Home from "./pages/home";
import Login from "./pages/login";
import Layout from "./components/layout";
import AuthServices from "./services/AuthServices";

const Ticket = React.lazy(() => import("./pages/ticket"));
const Detail = React.lazy(() => import("./pages/ticket/containers/detail"));
const Category = React.lazy(() => import("./pages/references/category"));
const PicUser = React.lazy(() => import("./pages/references/picUser"));
const Roles = React.lazy(() => import("./pages/references/roles"));
const Users = React.lazy(() => import("./pages/references/users"));

export default function Router() {
  const queryClient = new QueryClient();
  const token = window.sessionStorage.getItem("token");
  const usr = JSON.parse(localStorage.getItem("usr"));
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Layout />}>
          <Route index element={<PrivateRoute isAuthenticated={!!usr}><Home /></PrivateRoute>} />
          <Route path="ticket/:id" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <Ticket />
            </PrivateRoute>
          } />
          <Route path="detail-ticket/:id" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <Detail />
            </PrivateRoute>
          } />
          <Route path="category" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <Category />
            </PrivateRoute>
          } />
          <Route path="pic" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <PicUser />
            </PrivateRoute>
          } />
          <Route path="roles" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <Roles />
            </PrivateRoute>
          } />
          <Route path="users" element={
            <PrivateRoute isAuthenticated={!!usr}>
              <Users />
            </PrivateRoute>
          } />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </QueryClientProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
