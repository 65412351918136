import React from 'react'
import { Menu, Skeleton } from 'antd';
import CategoryServices from '../../services/CategoryServices';
import { useNavigate, useParams } from "react-router-dom";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const rootSubmenuKeys = ['dashboard','status', 'reference'];
function Sider(props) {
    const { handleToggle } = props;
    const usr = JSON.parse(localStorage.getItem('usr'));
    let navigate = useNavigate();
    let { id } = useParams();
    const [openKeys, setOpenKeys] = React.useState(['status']);
    const [selected, setSelected] = React.useState(id ? id === 'all' ? 'all' : id : 'dashboard');
    const [loading, setLoading] = React.useState(true);
    const [categoryList, setCategory] = React.useState([]);
    const handleClick = e => {
        setSelected(e.key);
        if (e.key === 'dashboard') {
            return navigate('/home');
        }
        if (e.key === 'roles'|| e.key === 'category'||e.key === 'users'||e.key === 'pic') {
            return navigate(`/home/${e?.key}`);
        }
        navigate(`/home/ticket/${e?.key}`);
        // handleToggle();
    };

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
      };

    React.useEffect(() => {
        function fetch() {
            if (categoryList.length === 0) {
                CategoryServices.Get({ limit: 200, page: 1 }).then((res) => {
                    setLoading(false);
                    res.data.forEach(element => {
                        setCategory(o => [...o, {
                            label: element.category_name,
                            value: element.category_id
                        }])
                    });
                }).catch(() => {
                    setLoading(true);
                });
            }
        }
        fetch();
    }, [categoryList])

    return (
        <Menu
            onClick={handleClick}
            style={{ height: '100%', borderRight: 0 }}
            selectedKeys={selected}
            defaultSelectedKeys={selected}
            selectable
            onOpenChange={onOpenChange}
            openKeys={openKeys} 
            mode="inline"
        >
            <Menu.Item key="dashboard">
                Dashboard
            </Menu.Item>
            <SubMenu key="status" icon={<MailOutlined />} title="Status PO">
                <Menu.Item key="all" >
                    All Tiket
                </Menu.Item>
                {!loading && categoryList.map((row, i) => (<Menu.Item key={row.value}>
                    {row.label}
                </Menu.Item>))}
                {loading && Array.from(new Array(8)).map((row, i) => (<Menu.Item>
                    <Skeleton active />
                </Menu.Item>))}
            </SubMenu>
            {usr?.idRole === '1' && <SubMenu key="reference" icon={<MailOutlined />} title="Configuration">
                <Menu.Item key="category" >
                   Status list
                </Menu.Item>
                <Menu.Item key="users" >
                   Users list
                </Menu.Item>
                <Menu.Item key="roles" >
                    Roles list
                </Menu.Item>
                <Menu.Item key="pic" >
                    PIC list
                </Menu.Item>
            </SubMenu>}
            {/* {!loading && categoryList.map((row, i) => (<Menu.Item key={row.value}>
                {row.label}
            </Menu.Item>))}
            {loading && Array.from(new Array(8)).map((row, i) => (<Menu.Item>
                <Skeleton active />
            </Menu.Item>))} */}
        </Menu>
    );
}

export default Sider